<template>
  <div class="success-cont">
    <div class="success-icon">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20ZM18 27.8285L30.9142 14.9142L28.0858 12.0858L18 22.1716L12.4142 16.5858L9.58582 19.4142L18 27.8285Z"
          fill="#00B42A" />
      </svg>
    </div>
    <div class="tips">
      <span class="success">
        recharged successfully
      </span>
      <div class="des">
        $ {{ chargeVal }} has been recharged, and the current balance is ${{ currentMoney }} .
      </div>
    </div>
    <a-button shape="round" type="primary" @click="$router.push('/usercenter/wallet')">Back To Wallet</a-button>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import local from '@/utils/local.js'
import { toThousands } from '@/utils/handleNumber.js'

const route = useRoute()
const chargeVal = route.query.chargeVal
let beforeMoney = local.get('monnum').replace(',', '')
console.log(beforeMoney)
let currentMoney = toThousands(Number(beforeMoney) + Number(chargeVal))
</script>

<style lang="less" scoped>
.success-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 234px;

  .tips {
    text-align: center;
    margin-top: 20px;

    .success {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-text-1);
    }

    .des {
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-3);
    }
  }

  button {
    margin-top: 30px;
    font-size: 16px;
    height: 33px;
  }
}
</style>
